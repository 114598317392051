<template>
  <headBackBar :iCustomizdde="true" :icon="true">
    <template v-slot:left-icon>
      <van-icon name="arrow-left" @click="goback" />
    </template>
    <template #default>
      <span>
        {{ $fanyi(title) }}
      </span>
    </template>
    <!--账户设置页面 密码，修改，更换绑定手机号，更换绑定手机号页面, -->
  </headBackBar>

  <!-- 内容区域 -->
  <div class="Content">
    <div class="main">
      <!----------------------------- 密码修改 -->
      <div class="Modificationmode" v-if="passwordstep == 1">
        <h2>{{ $fanyi("请选择认证方式") }}</h2>
        <div
          @click="
            (Verificationmode = 'password'), (passwordstep = 2), chushihuareg()
          "
        >
          {{ $fanyi("使用原密码进行验证") }}
        </div>
        <div
          @click="
            (Verificationmode = 'email'), (passwordstep = 2), chushihuareg()
          "
        >
          {{ $fanyi("使用电子邮箱进行验证") }}
        </div>
        <div
          @click="
            (Verificationmode = 'mobile'), (passwordstep = 2), chushihuareg()
          "
        >
          {{ $fanyi("使用手机号进行验证") }}
        </div>
      </div>
      <!-- 选在认证方式三种 -->
      <!-- 密码验证 -->
      <div
        class="password"
        v-if="passwordstep == 2 && Verificationmode == 'password'"
      >
        <h2>{{ $fanyi("请输入登录密码") }}</h2>
        <van-form>
          <van-field
            v-model="passworddata.password"
            :rules="[{ required: true, message: $fanyi('请输入登录密码') }]"
            :type="passworddata.passwordtype"
          >
            <!-- <template #right-icon>
              <img
                v-if="passworddata.passwordtype == 'text'"
                src="../../../../assets/register/psf6jdb4b7bul6anwcjhoyc6okp25iqe2fef905-6352-437e-987e-3612fa48c9c9.png"
                @click="passworddata.passwordtype = 'password'"
                alt=""
              />
              <img
                v-if="passworddata.passwordtype == 'password'"
                src="../../../../assets/register/pskiqjtniew8qn40zy6z27vmuj8pz83nlpk1ff25a83-0550-43e4-b065-7ac1b43e24c0.png"
                @click="passworddata.passwordtype = 'text'"
                alt=""
              />
            </template> -->
          </van-field>
        </van-form>
        <!-- 下一步 -->
        <div
          class="nextstep"
          @click="
            nextstep('password');
            chushihuadata();
          "
        >
          {{ $fanyi("下一步") }}
        </div>
      </div>
      <!-- 手机号验证 -->
      <div
        class="mobile"
        v-if="passwordstep == 2 && Verificationmode == 'mobile'"
      >
        <h2>
          {{ $fanyi("当前绑定手机号") }} {{ $store.state.userInfo.mobile }}
        </h2>
        <van-field
          v-model="mobiledata.code"
          :class="{ errinput: mobiledata.showerrortext }"
          @blur="codecheck('mobile')"
          type="text"
        >
          <template #right-icon>
            <van-button
              size="small"
              type="primary"
              v-if="mobileshow == true"
              @click.stop="sendSMS('mobile')"
              >{{ $fanyi("获取验证码") }}</van-button
            >
            <div v-else class="redCountdown">{{ Countdownmobile }}s</div>
            <van-icon v-if="mobiledata.showerrortext" name="cross" />
          </template>
        </van-field>
        <p class="error-text">
          {{ mobiledata.showerrortext ? $fanyi(errtext) : "" }}
        </p>
        <!-- 下一步 -->
        <div
          class="nextstep"
          @click="
            nextstep('mobile');
            chushihuadata();
          "
        >
          {{ $fanyi("下一步") }}
        </div>
      </div>
      <!-- 邮箱验证 -->
      <div
        class="email"
        v-if="passwordstep == 2 && Verificationmode == 'email'"
      >
        <h2>{{ $fanyi("电子邮箱") }} {{ $store.state.userInfo.email }}</h2>
        <van-field
          :class="{ errinput: emaildata.showerrortext }"
          @blur="codecheck('email')"
          v-model="emaildata.code"
          type="text"
        >
          <template #right-icon>
            <van-button
              size="small"
              v-if="show == true"
              @click.stop="sendSMS('email')"
              >{{ $fanyi("获取验证码") }}</van-button
            >
            <div v-else class="redCountdown">{{ Countdown }}s</div>
            <van-icon v-if="emaildata.showerrortext" name="cross" />
          </template>
        </van-field>
        <p class="error-text">
          {{ emaildata.showerrortext ? $fanyi(errtext) : "" }}
        </p>

        <!-- 下一步 -->
        <div
          class="nextstep"
          @click="
            nextstep('email');
            chushihuadata();
          "
        >
          {{ $fanyi("下一步") }}
        </div>
      </div>
      <!--  密码第三部修改密码 -->
      <div
        class="Modifythepassword"
        v-if="passwordstep == 3 && route.query.type == 'password'"
      >
        <h2>{{ $fanyi("修改新密码") }}</h2>
        <!-- 表单 -->
        <van-form ref="passwordform">
          <van-field
            v-model.trim="revisedata.password"
            :placeholder="$fanyi('请输入新密码')"
            name="password"
            @blur="
              validateField('password');
              revisedata.password && revisedata.word
                ? validateField('word')
                : '';
            "
            :class="{ errinput: revisedata.passworderr }"
            :rules="[
              {
                required: true,
                message: $fanyi(
                  '密码为6-16位，可由字母数字及@符号组成，不支持空格 6 到 16 位的密码,不支持空格'
                ),

                trigger: ['onBlur', 'onChange'],
              },
              {
                validator: validatorpassword,
                trigger: 'onBlur',
              },
            ]"
            :type="revisedata.passwordtype"
          >
            <template #right-icon>
              <img
                v-if="revisedata.passwordtype == 'text'"
                src="../../../../assets/register/psf6jdb4b7bul6anwcjhoyc6okp25iqe2fef905-6352-437e-987e-3612fa48c9c9.png"
                @click="revisedata.passwordtype = 'password'"
                alt=""
              />
              <img
                v-if="revisedata.passwordtype == 'password'"
                src="../../../../assets/register/pskiqjtniew8qn40zy6z27vmuj8pz83nlpk1ff25a83-0550-43e4-b065-7ac1b43e24c0.png"
                @click="revisedata.passwordtype = 'text'"
                alt=""
              />
              <van-icon v-if="revisedata.passworderr" name="cross" />
            </template>
          </van-field>
          <van-field
            v-model.trim="revisedata.word"
            name="word"
            :placeholder="$fanyi('请再次输入新密码进行确认')"
            :class="{ errinput: revisedata.worderr }"
            :rules="[
              {
                required: true,
                required: true,
                message: $fanyi('请输入密码'),
                validator: validatorPasswordconfirmation,
                trigger: 'onBlur',
              },
            ]"
            @blur="validateField('word')"
            :type="revisedata.wordtype"
          >
            <template #right-icon>
              <img
                v-if="revisedata.wordtype == 'text'"
                src="../../../../assets/register/psf6jdb4b7bul6anwcjhoyc6okp25iqe2fef905-6352-437e-987e-3612fa48c9c9.png"
                @click="revisedata.wordtype = 'password'"
                alt=""
              />
              <img
                v-if="revisedata.wordtype == 'password'"
                src="../../../../assets/register/pskiqjtniew8qn40zy6z27vmuj8pz83nlpk1ff25a83-0550-43e4-b065-7ac1b43e24c0.png"
                @click="revisedata.wordtype = 'text'"
                alt=""
              />
              <van-icon v-if="revisedata.worderr" name="cross" />
            </template>
          </van-field>
          <!-- 立即修改 -->
          <div class="nextstep" @click="revise()">
            {{ $fanyi("立即修改") }}
          </div>
        </van-form>
      </div>
      <!------------------------  邮箱修改 -->
      <div
        class="Modifyemaildata"
        v-if="route.query.type == 'email' && passwordstep == 3"
      >
        <h2>{{ $fanyi("请输入您的新邮箱") }}：</h2>
        <!-- 表单 -->
        <van-form ref="emailform">
          <van-field
            v-model.trim="Modifyemaildata.email"
            name="email"
            @blur="validatemailField('email')"
            :class="{ errinput: Modifyemaildata.showemailerror }"
            :rules="[
              {
                type: 'email',
                trigger: 'onBlur',
                required: true,
                message: $fanyi('请输入邮箱地址'),
              },
              {
                validator: validatoremail,
                trigger: 'onBlur',
              },
            ]"
          >
            <template #right-icon> </template>
          </van-field>
          <van-field
            v-model.trim="Modifyemaildata.code"
            name="code"
            :class="{ errinput: Modifyemaildata.showcodeerror }"
            :rules="[
              {
                required: true,
                message: $fanyi('请输入验证码'),
                validator: validatoremailcode,
                trigger: 'onBlur',
              },
            ]"
            @blur="validatemailField('code')"
            type="text"
          >
            <template #right-icon>
              <van-button
                size="small"
                v-if="Modifyemaildata.show == true"
                @click.stop="ModifysendSMS('email')"
                >{{ $fanyi("获取验证码") }}</van-button
              >
              <div v-else class="redCountdown">
                {{ Modifyemaildata.Countdown }}s
              </div>

              <van-icon v-if="Modifyemaildata.showcodeerror" name="cross" />
            </template>
          </van-field>
          <!-- 立即修改 -->
          <div class="nextstep" @click="emailrevise()">
            {{ $fanyi("立即修改") }}
          </div>
        </van-form>
      </div>
      <!------------------------  手机号修改 -->

      <div
        class="Modifyemaildata"
        v-if="route.query.type == 'mobile' && passwordstep == 3"
      >
        <h2>{{ $fanyi("修改新手机号") }}</h2>
        <!-- 表单 -->
        <van-form ref="mobileform">
          <van-field
            v-model.trim="Modifymobiledata.mobile"
            name="mobile"
            @blur="validatmobileField('mobile')"
            :class="{ errinput: Modifymobiledata.showemailerror }"
            :rules="[
              {
                required: true,
                message: $fanyi('手机号不能为空'),
                trigger: 'onBlur',
              },

              {
                validator: validatormobile,
                trigger: 'onBlur',
              },
            ]"
          >
            <template #right-icon> </template>
          </van-field>
          <van-field
            v-model.trim="Modifymobiledata.code"
            name="code"
            :class="{ errinput: Modifymobiledata.showcodeerror }"
            :rules="[
              {
                required: true,
                message: $fanyi('请输入验证码'),
                validator: validatormobilecode,
                trigger: 'onBlur',
              },
            ]"
            @blur="validatmobileField('code')"
            type="text"
          >
            <template #right-icon>
              <van-button
                size="small"
                v-if="Modifymobiledata.show == true"
                @click.stop="ModifysendSMS('mobile')"
                >{{ $fanyi("获取验证码") }}</van-button
              >
              <div v-else class="redCountdown">
                {{ Modifymobiledata.Countdown }}s
              </div>

              <van-icon v-if="Modifymobiledata.showcodeerror" name="cross" />
            </template>
          </van-field>
          <!-- 立即修改 -->
          <div class="nextstep" @click="mobilerevise()">
            {{ $fanyi("立即修改") }}
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>
<script setup>
import dayjs from "dayjs";
import headBackBar from "../../../../components/headGoBack/index.vue";
import { getCurrentInstance, ref } from "vue";
import { useRoute } from "vue-router";
import { Toast } from "vant";
const { proxy } = getCurrentInstance();
const route = useRoute();

const passwordstep = ref(1); // 密码步骤
const title = ref(""); // 页面标题
const Verificationmode = ref(""); // 认证方式
// 首先判断是从哪里过来的 密码修改， 邮箱修改，电话修改
if (route.query.type == "password") {
  title.value = "密码修改";
}
if (route.query.type == "email") {
  title.value = "邮箱修改";
}
if (route.query.type == "mobile") {
  title.value = "手机号修改";
}
const goback = () => {
  passwordstep.value == 1
    ? proxy.$fun.routerToPage("/user/accountsetting")
    : (passwordstep.value = passwordstep.value - 1);
  emaildata.value.success = false;
  mobiledata.value.success = false;
};
// ----------------------------修改密码数据
const show = ref(true); // 控制发送验证码按钮
const mobileshow = ref(true); //
const errtext = ref("验证码错误");
const Countdown = ref(300); // 倒计时
const Countdownmobile = ref(300); // 倒计时
//选择修改密码 选择认证方式第二部 定义数据
const passworddata = ref({
  password: "",
  email: "",
  passwordtype: "password",
  mobile: "",
});
// 手机号码数据
const mobiledata = ref({
  code: "", // 邮箱验证码
  showerrortext: false, //  控制验证码文本显示
  success: false, //
});
// 邮箱数据
const emaildata = ref({
  code: "", // 邮箱验证码
  showerrortext: false, //  控制验证码文本显示
  success: false, //
});
// 初始化验证表单数据
const chushihuareg = () => {
  passworddata.value = {
    password: "",
    email: "",
    passwordtype: "password",
    mobile: "",
  };
  mobiledata.value = {
    code: "", // 邮箱验证码
    showerrortext: false, //  控制验证码文本显示
    success: false, //
  };
  emaildata.value = {
    code: "", // 邮箱验证码
    showerrortext: false, //  控制验证码文本显示
    success: false, //
  };
};
// 初始化提交表单数据
const chushihuadata = () => {
  Modifymobiledata.value = {
    mobile: "",
    code: "",
    show: true,
    showemailerror: false,
    whetheruse: false,
    Countdown: 300,
    showcodeerror: false,
  };
  revisedata.value = {
    passwordtype: "password",
    wordtype: "password",
    password: "",
    word: "",
    passworderr: false,
    worderr: false,
    type: "",
  };
  Modifyemaildata.value = {
    email: "",
    mobile: "",
    code: "",
    show: true,
    showemailerror: false,
    whetheruse: false,
    Countdown: 300,
    showcodeerror: false,
  };
};
// 验证码校验函数
const codecheck = async (type) => {
  // 如果是修改密码
  if (route.query.type == "password") {
    if (type == "email") {
      if (emaildata.value.code == "") {
        emaildata.value.showerrortext = true;
        errtext.value = "请输入验证码";
      } else {
        const res = await proxy.$api.checkUpdatePasswordValidationCode({
          code: emaildata.value.code,
          check_number_type: type,
        });
        if (res.code !== 0) {
          Toast.fail(proxy.$fanyi("验证码错误"));
          emaildata.value.showerrortext = true;
          emaildata.value.success = false;
        } else {
          emaildata.value.showerrortext = false;
          emaildata.value.success = true;
        }
      }
    }
    if (type == "mobile") {
      if (mobiledata.value.code == "") {
        mobiledata.value.showerrortext = true;
        errtext.value = "请输入验证码";
      } else {
        const res = await proxy.$api.checkUpdatePasswordValidationCode({
          code: mobiledata.value.code,
          check_number_type: type,
        });
        if (res.code !== 0) {
          Toast.fail(proxy.$fanyi("验证码错误"));
          mobiledata.value.showerrortext = true;
          mobiledata.value.success = false;
        } else {
          mobiledata.value.showerrortext = false;
          mobiledata.value.success = true;
        }
      }
    }
  }
  // r如果是修改邮箱
  if (route.query.type == "email") {
    if (type == "email") {
      if (emaildata.value.code == "") {
        emaildata.value.showerrortext = true;
        errtext.value = "请输入验证码";
      } else {
        const res = await proxy.$api.checkUpdateEmailValidationCode({
          code: emaildata.value.code,
          check_number_type: type,
        });
        if (res.code !== 0) {
          Toast.fail(proxy.$fanyi("验证码错误"));
          emaildata.value.showerrortext = true;
          emaildata.value.success = false;
        } else {
          emaildata.value.showerrortext = false;
          emaildata.value.success = true;
        }
      }
    }
    if (type == "mobile") {
      if (mobiledata.value.code == "") {
        mobiledata.value.showerrortext = true;
        errtext.value = "请输入验证码";
      } else {
        const res = await proxy.$api.checkUpdateEmailValidationCode({
          code: mobiledata.value.code,
          check_number_type: type,
        });
        if (res.code !== 0) {
          Toast.fail(proxy.$fanyi("验证码错误"));
          mobiledata.value.showerrortext = true;
          mobiledata.value.success = false;
        } else {
          mobiledata.value.showerrortext = false;
          mobiledata.value.success = true;
        }
      }
    }
  }
  // 如果是修改手机号
  if (route.query.type == "mobile") {
    if (type == "email") {
      if (emaildata.value.code == "") {
        emaildata.value.showerrortext = true;
        errtext.value = "请输入验证码";
      } else {
        const res = await proxy.$api.checkUpdateMobileValidationCode({
          code: emaildata.value.code,
          check_number_type: type,
        });
        if (res.code !== 0) {
          Toast.fail(proxy.$fanyi("验证码错误"));
          emaildata.value.showerrortext = true;
          emaildata.value.success = false;
        } else {
          emaildata.value.showerrortext = false;
          emaildata.value.success = true;
        }
      }
    }
    if (type == "mobile") {
      if (mobiledata.value.code == "") {
        mobiledata.value.showerrortext = true;
        errtext.value = "请输入验证码";
      } else {
        const res = await proxy.$api.checkUpdateMobileValidationCode({
          code: mobiledata.value.code,
          check_number_type: type,
        });
        if (res.code !== 0) {
          Toast.fail(proxy.$fanyi("验证码错误"));
          mobiledata.value.showerrortext = true;
          mobiledata.value.success = false;
        } else {
          mobiledata.value.showerrortext = false;
          mobiledata.value.success = true;
        }
      }
    }
  }
};
// 第二步密码校验是否正确
const passwordverifier = async () => {
  if (passworddata.value.password == "") {
    Toast(proxy.$fanyi("请输入登录密码"));
  } else {
    const res = await proxy.$api.userCheckPassword({
      password: passworddata.value.password,
    });
    if (res.code != 0) return Toast.fail(proxy.$fanyi("登录密码错误"));
    Toast(proxy.$fanyi("验证成功"));
    setTimeout(() => {
      passwordstep.value = 3;
      revisedata.value.type = "password";
    }, 500);
  }
};
// 发送验证码
const nextstep = async (type) => {
  // 判断是password  email mobile
  if (type == "password") {
    await passwordverifier();
  }
  if (type == "email") {
    await codecheck("email");
    if (emaildata.value.success) {
      Toast(proxy.$fanyi("验证成功"));
      setTimeout(() => {
        passwordstep.value = 3;
        show.value = true;
        revisedata.value.type = "email";
        Countdown.value = 300;
      }, 500);
    }
  }
  if (type == "mobile") {
    await codecheck("mobile");
    console.log(mobiledata.value.success);
    if (mobiledata.value.success) {
      Toast(proxy.$fanyi("验证成功"));
      setTimeout(() => {
        passwordstep.value = 3;
        mobileshow.value = true;
        revisedata.value.type = "mobile";
        Countdownmobile.value = 300;
      }, 500);
    }
  }
};
// 修改第二步密码发送验证码
const sendSMS = async (type) => {
  // 进行判断是不是修改密码
  if (route.query.type == "password") {
    if (type == "email") {
      Toast.loading({
        message: proxy.$fanyi("加载中..."),
        forbidClick: true,
      });
      const res = await proxy.$api.getUpdatePasswordVerificationCode({
        check_number_type: "email",
      });
      console.log(res);
      if (res.code != 0) return Toast.fail(proxy.$fanyi("操作失败"));
      show.value = false;
      // 300秒倒计时

      var timer = setInterval(() => {
        Countdown.value--;
        if (Countdown.value <= 0) {
          show.value = true;
          Countdown.value = 300;
          clearInterval(timer);
        }
      }, 1000);
    }
    if (type == "mobile") {
      Toast.loading({
        message: proxy.$fanyi("加载中..."),
        forbidClick: true,
      });
      const res = await proxy.$api.getUpdatePasswordVerificationCode({
        check_number_type: "mobile",
      });
      console.log(res);
      if (res.code != 0) return Toast.fail(proxy.$fanyi("操作失败"));
      mobileshow.value = false;
      // 300秒倒计时

      var timer = setInterval(() => {
        Countdownmobile.value--;
        if (Countdownmobile.value <= 0) {
          mobileshow.value = true;
          Countdownmobile.value = 300;
          clearInterval(timer);
        }
      }, 1000);
    }
  }
  // 判断是不是修改邮箱
  if (route.query.type == "email") {
    if (type == "email") {
      Toast.loading({
        message: proxy.$fanyi("加载中..."),
        forbidClick: true,
      });
      const res = await proxy.$api.getUpdateEmailVerificationCode({
        check_number_type: "email",
      });
      console.log(res);
      if (res.code != 0) return Toast.fail(proxy.$fanyi("操作失败"));
      show.value = false;
      // 300秒倒计时

      var timer = setInterval(() => {
        Countdown.value--;
        if (Countdown.value <= 0) {
          show.value = true;
          Countdown.value = 300;
          clearInterval(timer);
        }
      }, 1000);
    }
    if (type == "mobile") {
      Toast.loading({
        message: proxy.$fanyi("加载中..."),
        forbidClick: true,
      });
      const res = await proxy.$api.getUpdateMobileVerificationCode({
        check_number_type: "mobile",
      });
      console.log(res);
      if (res.code != 0) return Toast.fail(proxy.$fanyi("操作失败"));
      mobileshow.value = false;
      // 300秒倒计时

      var timer = setInterval(() => {
        Countdownmobile.value--;
        if (Countdownmobile.value <= 0) {
          mobileshow.value = true;
          Countdownmobile.value = 300;
          clearInterval(timer);
        }
      }, 1000);
    }
  }
  // 判断是不是修改手机号码
  if (route.query.type == "mobile") {
    if (type == "email") {
      Toast.loading({
        message: proxy.$fanyi("加载中..."),
        forbidClick: true,
      });
      const res = await proxy.$api.getUpdatePasswordVerificationCode({
        check_number_type: "email",
      });
      console.log(res);
      if (res.code != 0) return Toast.fail(proxy.$fanyi("操作失败"));
      show.value = false;
      // 300秒倒计时

      var timer = setInterval(() => {
        Countdown.value--;
        if (Countdown.value <= 0) {
          show.value = true;
          Countdown.value = 300;
          clearInterval(timer);
        }
      }, 1000);
    }
    if (type == "mobile") {
      Toast.loading({
        message: proxy.$fanyi("加载中..."),
        forbidClick: true,
      });
      const res = await proxy.$api.getUpdatePasswordVerificationCode({
        check_number_type: "mobile",
      });
      console.log(res);
      if (res.code != 0) return Toast.fail(proxy.$fanyi("操作失败"));
      mobileshow.value = false;
      // 300秒倒计时

      var timer = setInterval(() => {
        Countdownmobile.value--;
        if (Countdownmobile.value <= 0) {
          mobileshow.value = true;
          Countdownmobile.value = 300;
          clearInterval(timer);
        }
      }, 1000);
    }
  }
};
// 修改密码第三部 ，修改密码数据
const passwordform = ref("");
const revisedata = ref({
  passwordtype: "password",
  wordtype: "password",
  password: "",
  word: "",
  passworderr: false,
  worderr: false,
  type: "",
});
// 密码框失去焦点校验表单 然后改变状态
const validateField = (type, name) => {
  // console.log(type)
  // console.log(passwordform.value)
  passwordform.value
    .validate(type)
    .then((res) => {
      if (type == "password") {
        revisedata.value.passworderr = false;
      } else {
        revisedata.value.worderr = false;
      }
    })
    .catch(() => {
      if (type == "password") {
        revisedata.value.passworderr = true;
      } else {
        revisedata.value.worderr = true;
      }
    });
};
const validatorpassword = (value) => {
  let reg = /^[^\s]{6,16}$/;
  console.log(reg.test(value));
  if (!reg.test(value)) {
    return proxy.$fanyi(
      "密码为6-16位,可由大小写字母数字及特殊符号组成，不支持空格"
    );
  }
};
// 密码确认
const validatorPasswordconfirmation = (val) => {
  if (!val) {
    return proxy.$fanyi("请输入确认密码");
  } else if (val !== revisedata.value.password) {
    return proxy.$fanyi("两次输入密码不一致");
  }
};
// 退出登录
const logout = () => {
  Toast.clear();
  Toast.success(proxy.$fanyi("密码修改成功，需要重新登录"));
  localStorage.removeItem("japan_user_token");
  proxy.$store.commit("userData", null);
  proxy.$store.commit("setcartCount", 0);
  proxy.$store.commit("outUser");
  proxy.$fun.routerToPage("/");
};
// 点击密码立即修改
const revise = async () => {
  try {
    await passwordform.value.validate();
    Toast.loading({
      duration: 0,
      message: proxy.$fanyi("加载中..."),
      forbidClick: true,
    });
    // 校验通过了，进行判断是通过密码修改验证码还是验证码
    if (revisedata.value.type == "password") {
      proxy.$api
        .updatePasswordViaOldPassword({
          old_password: passworddata.value.password,
          new_password: revisedata.value.word,
        })
        .then((res) => {
          if (res.code != 0) {
            Toast.fail({
              message: proxy.$fanyi(res.msg),
              className: "noticeWidth",
            });

            Toast.clear();
            return;
          }

          logout();
        });
    }
    if (revisedata.value.type == "email") {
      proxy.$api
        .updatePasswordViaValidationCode({
          new_password: revisedata.value.word,
          check_number_type: "email",
          code: emaildata.value.code,
        })
        .then((res) => {
          if (res.code != 0) {
            Toast.fail(proxy.$fanyi(res.msg));
            Toast.clear();
            return;
          }
          logout();
        });
    }

    if (revisedata.value.type == "mobile") {
      proxy.$api
        .updatePasswordViaValidationCode({
          new_password: revisedata.value.word,
          check_number_type: "mobile",
          code: mobiledata.value.code,
        })
        .then((res) => {
          if (res.code != 0) {
            Toast.fail(proxy.$fanyi(res.msg));
            Toast.clear();
            return;
          }
          logout();
        });
    }
  } catch (e) {}
};

// -----------------------修改邮箱数据
const emailform = ref(""); // 修改邮箱表单
const Modifyemaildata = ref({
  email: "",
  code: "",
  show: true,
  whetheruse: false,
  showemailerror: false,
  Countdown: 300,
  showcodeerror: false,
});

// 修改手机和邮箱发送验证码
const ModifysendSMS = async (type) => {
  // 进行判断是邮箱还是手机修改
  if (type == "email") {
    if (Modifyemaildata.value.email == "") {
      Toast(proxy.$fanyi("请先输入邮箱"));
    } else {
      if (Modifyemaildata.value.whetheruse == true) return;
      Toast.loading({
        message: proxy.$fanyi("加载中..."),
        forbidClick: true,
      });
      const res = await proxy.$api.getUpdateEmailVerificationCode({
        check_number_type: "email",
        new_email: Modifyemaildata.value.email,
      });
      console.log(res);

      if (res.code != 0) {
        Toast.fail(proxy.$fanyi(res.msg));
        return;
      }
      Modifyemaildata.value.show = false;
      // 300秒倒计时

      var timer = setInterval(() => {
        Modifyemaildata.value.Countdown--;
        if (Modifyemaildata.value.Countdown <= 0) {
          Modifyemaildata.value.show = true;
          Modifyemaildata.value.Countdown = 300;
          clearInterval(timer);
        }
      }, 1000);
    }

    // getUpdateEmailVerificationCode
  }
  if (type == "mobile") {
    if (Modifymobiledata.value.mobile == "") {
      Toast(proxy.$fanyi("请先输入手机号"));
    } else {
      if (Modifymobiledata.value.whetheruse == true) return;
      Toast.loading({
        message: proxy.$fanyi("加载中..."),
        forbidClick: true,
      });
      const res = await proxy.$api.getUpdateMobileVerificationCode({
        check_number_type: "mobile",
        new_mobile: Modifymobiledata.value.mobile,
      });
      console.log(res);

      if (res.code != 0) {
        Toast.fail(proxy.$fanyi(res.msg));
        return;
      }
      Modifymobiledata.value.show = false;
      // 300秒倒计时

      var timer = setInterval(() => {
        Modifymobiledata.value.Countdown--;
        if (Modifymobiledata.value.Countdown <= 0) {
          Modifymobiledata.value.show = true;
          Modifymobiledata.value.Countdown = 300;
          clearInterval(timer);
        }
      }, 1000);
    }
  }
};
// 修改邮箱 失焦校验函数
const validatemailField = (type) => {
  emailform.value
    .validate(type)
    .then((res) => {
      if (type == "email") {
        Modifyemaildata.value.showemailerror = false;
      }
      if (type == "code") {
        Modifyemaildata.value.showcodeerror = false;
      }
    })
    .catch(() => {
      if (type == "email") {
        Modifyemaildata.value.showemailerror = true;
      }
      if (type == "code") {
        Modifyemaildata.value.showcodeerror = true;
      }
    });
};
// 邮箱骄校验
const validatoremail = async (val) => {
  if (
    /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/.test(
      val
    ) === false
  ) {
    return proxy.$fanyi("输入的邮箱格式不正确");
  }
  let res = await proxy.$api.checkRegisterField({
    field: "email",
    value: val,
  });
  if (res.success == false) {
    // console.log(res.msg.substr(-5)==格式不正确);
    Modifyemaildata.value.email = "";
    Modifyemaildata.value.whetheruse = true;
    if (res.msg.substr(-5) == "格式不正确") {
      return proxy.$fanyi("输入的邮箱格式不正确");
    } else {
      return proxy.$fanyi("当前邮箱已被使用");
    }
  } else {
    Modifyemaildata.value.whetheruse = false;
  }
};
// 验证码
const validatoremailcode = async (val) => {
  if (val == "") {
    return proxy.$fanyi("请输入验证吗");
  } else {
    const res = await proxy.$api.checkUpdateEmailValidationCode({
      code: val,
      check_number_type: "email",
      new_email: Modifyemaildata.value.email,
    });
    if (res.code !== 0) {
      return proxy.$fanyi(res.msg);
    }
  }
};
// 邮箱立即修改
const emailrevise = async () => {
  try {
    await emailform.value.validate();
    Toast.loading({
      duration: 0,
      message: proxy.$fanyi("加载中..."),
      forbidClick: true,
    });
    // 通过验证码修改 revisedata 是email 或者 mobile
    if (revisedata.value.type == "mobile" || revisedata.value.type == "email") {
      proxy.$api
        .updateEmailViaValidationCode({
          new_email: Modifyemaildata.value.email,
          code: Modifyemaildata.value.code,
        })
        .then((res) => {
          if (res.code != 0) {
            Toast.fail({
              message: proxy.$fanyi(res.msg),
              className: "noticeWidth",
            });
            Toast.clear();
            return;
          }
          Toast.clear();
          Toast.success(proxy.$fanyi("邮箱修改成功"));
          proxy.$fun.routerToPage("/user/accountsetting");
        });
    } else {
      // 通过密码修改 password

      proxy.$api
        .updateEmailViaOldPassword({
          new_email: Modifyemaildata.value.email,
          password: passworddata.value.password,
        })
        .then((res) => {
          if (res.code != 0) {
            Toast.fail({
              message: proxy.$fanyi(res.msg),
              className: "noticeWidth",
            });
            Toast.clear();
            return;
          }
          Toast.clear();
          Toast.success(proxy.$fanyi("邮箱修改成功"));
          proxy.$fun.routerToPage("/user/accountsetting");
        });
    }
  } catch (e) {}
};
// -----------------------修改手机号数据
const Modifymobiledata = ref({
  mobile: "",
  code: "",
  show: true,
  showemailerror: false,
  whetheruse: false,
  Countdown: 300,
  showcodeerror: false,
});
const mobileform = ref(""); // 修改邮箱表单
const validatmobileField = (type) => {
  mobileform.value
    .validate(type)
    .then((res) => {
      if (type == "mobile") {
        Modifymobiledata.value.showemailerror = false;
      }
      if (type == "code") {
        Modifymobiledata.value.showcodeerror = false;
      }
    })
    .catch(() => {
      if (type == "mobile") {
        Modifymobiledata.value.showemailerror = true;
      }
      if (type == "code") {
        Modifymobiledata.value.showcodeerror = true;
      }
    });
};
// 手机号校验
const validatormobile = async (val) => {
  // if (/^(\w){11}$/.test(val) === false) {
  //   return proxy.$fanyi("请输入正确的手机号码");
  // }
  let res = await proxy.$api.checkRegisterField({
    field: "mobile",
    value: val,
  });
  if (res.success == false) {
    Modifymobiledata.value.mobile = "";
    Modifymobiledata.value.whetheruse = true;
    if (res.msg.substr(-5) == "格式不正确") {
      return proxy.$fanyi("手机号码格式不正确");
    } else {
      return proxy.$fanyi("手机号码已被使用");
    }
  } else {
    Modifymobiledata.value.whetheruse = false;
  }
};
// 验证码校验
const validatormobilecode = async (val) => {
  if (val == "") {
    return proxy.$fanyi("请输入验证吗");
  } else {
    const res = await proxy.$api.checkUpdateMobileValidationCode({
      code: val,
      check_number_type: "mobile",
      new_mobile: Modifymobiledata.value.mobile,
    });
    if (res.code !== 0) {
      return proxy.$fanyi(res.msg);
    }
  }
};
// 手机号立即修改
const mobilerevise = async () => {
  try {
    await mobileform.value.validate();
    Toast.loading({
      duration: 0,
      message: proxy.$fanyi("加载中..."),
      forbidClick: true,
    });
    // 通过验证码 修改
    if (revisedata.value.type == "mobile" || revisedata.value.type == "email") {
      proxy.$api
        .updateMobileViaValidationCode({
          new_mobile: Modifymobiledata.value.mobile.toString(),
          code: Modifymobiledata.value.code,
        })
        .then((res) => {
          if (res.code != 0) {
            Toast.fail({
              message: proxy.$fanyi(res.msg),
              className: "noticeWidth",
            });
            Toast.clear();
            return;
          }
          Toast.clear();
          Toast.success(proxy.$fanyi("手机号修改成功"));
          proxy.$fun.routerToPage("/user/accountsetting");
        });
    } else {
      // 通过密码修改
      proxy.$api
        .updateMobileViaOldPassword({
          new_mobile: Modifymobiledata.value.mobile.toString(),
          password: passworddata.value.password,
        })
        .then((res) => {
          if (res.code != 0) {
            Toast.fail({
              message: proxy.$fanyi(res.msg),
              className: "noticeWidth",
            });
            Toast.clear();
            return;
          }
          Toast.clear();
          Toast.success(proxy.$fanyi("手机号修改成功"));
          proxy.$fun.routerToPage("/user/accountsetting");
        });
    }
  } catch (e) {}
};
</script>
<style>
.van-toast--fail {
  min-width: 300px;
}

.van-toast {
  min-width: 250px;
}
</style>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.headBox {
  span {
    font-size: 32px;
    font-family: PingFang SC !important;
    font-weight: 600;
    color: #000000;
  }
}

.Content {
  width: 750px;
  height: 1400px;
  background: #f6f6f6;
  background: #f6f6f6;
  padding: 0 30px;
  padding-top: 20px;

  .main {
    width: 100%;
    height: 1380px;
    background-color: #fff;
    padding: 0 60px;
    padding-top: 59px;

    .errinput {
      :deep() input {
        background: #faf2f2 !important;
        border: 1px solid #b4272b !important;
        border-radius: 6px;
      }
    }

    .error-text {
      font-size: 20px;

      font-weight: 400;
      height: 20px;
      color: #b4272b;
      padding-left: 24px;
      margin-top: 10px;
    }

    .redCountdown {
      width: 171px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.37333rem !important;

      font-weight: 400;
      padding-left: 20px;
      color: #b4272b;
    }

    .van-icon-cross {
      position: absolute;
      right: 181px;
      font-size: 25px;
      font-weight: 600;
      color: #b4272b;
    }

    :deep().van-field__control {
      font-size: 28px;
      &::placeholder {
        font-size: 20px;
      }
    }

    :deep().van-field__error-message {
      padding: 0;
      padding-left: 24px;
      font-size: 20px;
      min-height: 30px;
      line-height: 30px;
      font-weight: 400;
      color: #b4272b;
      height: unset;
    }

    // 密码修改选择认证方式
    .Modificationmode {
      h2 {
        font-size: 30px;

        font-weight: 600;
        color: #000000;
        margin-bottom: 45px;
      }

      div {
        width: 570px;
        height: 70px;
        background: #b4272b;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 28px;

        font-weight: 400;
        color: #ffffff;
        margin-bottom: 30px;
      }
    }

    .nextstep {
      width: 570px;
      height: 70px;
      background: #b4272b;
      border-radius: 6px;
      font-size: 28px;

      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
    }

    // 第二步
    .password {
      h2 {
        font-size: 30px;

        font-weight: 600;
        color: #000000;
        margin-bottom: 45px;
      }

      .van-cell {
        margin: 0;
        padding: 0;
      }

      .van-cell:after {
        border-bottom: 0;
      }

      :deep().van-cell__value {
        min-height: 100px;
      }

      input {
        width: 570px;
        height: 70px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        font-size: 28px !important;
      }

      :deep() .van-field__right-icon {
        display: flex;
        align-items: center;
        position: absolute;
        right: 20px;

        img {
          width: 24px;
          height: 16px;
        }
      }
    }

    .mobile {
      h2 {
        font-size: 30px;

        font-weight: 600;
        color: #000000;
        margin-bottom: 45px;
      }

      .van-cell {
        margin: 0;
        padding: 0;
      }

      input {
        width: 570px;
        height: 70px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        font-size: 28px !important;
      }

      :deep() .van-field__right-icon {
        position: absolute;
        right: 20px;
        align-items: center;
        display: flex;

        .van-button {
          font-size: 28px !important;
          font-weight: 400;
          color: #b4272b;
          border: none !important;
          background-color: transparent;

          .van-button__text {
            font-size: 20px !important;

            font-weight: 400;
            color: #b4272b;
          }
        }
      }
    }

    .email {
      h2 {
        font-size: 30px;

        font-weight: 600;
        color: #000000;
        margin-bottom: 45px;
      }

      .van-cell {
        margin: 0;
        padding: 0;
      }

      input {
        width: 570px;
        height: 70px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        font-size: 28px !important;
      }

      :deep() .van-field__right-icon {
        position: absolute;
        right: 20px;
        display: flex;
        align-items: center;

        .van-button {
          font-size: 28px !important;

          font-weight: 400;
          color: #b4272b;
          border: none !important;
          background-color: transparent;

          .van-button__text {
            font-size: 20px !important;

            font-weight: 400;
            color: #b4272b;
          }
        }
      }
    }

    // 密码修改第三部
    .Modifythepassword {
      h2 {
        font-size: 30px;

        font-weight: 600;
        color: #000000;
        margin-bottom: 45px;
      }

      .van-cell {
        margin: 0;
        padding: 0;
      }

      .van-cell:after {
        border-bottom: 0;
      }

      :deep().van-field__error-message {
        padding-left: 24px;
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
        color: #b4272b;
      }

      input {
        width: 570px;
        height: 70px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        font-size: 28px !important;
      }

      .van-icon-cross {
        position: absolute;
        right: 94px;
        font-size: 25px;
        font-weight: 600;
        color: #b4272b;
      }

      :deep().van-cell__value {
        min-height: 100px;
      }

      :deep() .van-field__right-icon {
        position: absolute;
        right: 20px;
        display: flex;
        align-items: center;

        img {
          width: 24px;
          height: 16px;
        }
      }
    }

    // 修改邮箱样式
    .Modifyemaildata {
      h2 {
        font-size: 30px;

        font-weight: 600;
        color: #000000;
        margin-bottom: 45px;
      }

      .van-cell {
        margin: 0;
        padding: 0;
      }

      .van-cell:after {
        border-bottom: 0;
      }

      :deep().van-field__error-message {
        padding-left: 24px;
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
        color: #b4272b;
      }

      input {
        width: 570px;
        height: 70px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        font-size: 28px !important;
      }

      .van-icon-cross {
        position: absolute;
        right: 94px;
        font-size: 25px;
        font-weight: 600;
        color: #b4272b;
      }

      :deep().van-cell__value {
        min-height: 100px;
      }

      :deep() .van-field__right-icon {
        position: absolute;
        right: 20px;
        display: flex;
        align-items: center;

        .van-button {
          font-size: 28px !important;

          font-weight: 400;
          color: #b4272b;
          border: none !important;
          background-color: transparent;

          .van-button__text {
            font-size: 20px !important;

            font-weight: 400;
            color: #b4272b;
          }
        }
      }

      .van-icon-cross {
        position: absolute;
        right: 181px;
      }

      :deep() .van-field__right-icon {
        position: absolute;
        right: 20px;
        display: flex;
        align-items: center;

        img {
          width: 24px;
          height: 16px;
        }
      }
    }
  }
}
</style>
